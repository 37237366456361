import { trackEvent } from '@pm/analytics';
import clsx from 'clsx';
import {
  FunctionComponent,
  MouseEventHandler,
  PropsWithChildren,
  ReactElement,
} from 'react';
import { NavLink, NavLinkProps, To } from 'react-router-dom';
import { Brand } from '../../types';
import { composeExternalLinkAttributes } from '../../utilities/dom';

type Props = {
  to: To;
  end?: NavLinkProps['end'];
  icon: ReactElement;
  close?: () => void;
  desktop?: boolean;
  className?: string;
  badge?: JSX.Element | null;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  external?: boolean;
  brandName: Brand;
};

export const MenuLink: FunctionComponent<PropsWithChildren<Props>> = ({
  to,
  end,
  icon,
  desktop,
  badge,
  close,
  className,
  children,
  onClick,
  external = false,
  brandName,
}) => {
  const { rel, target } = composeExternalLinkAttributes({
    external,
  });
  return (
    <NavLink
      to={to}
      end={end}
      rel={rel}
      target={target}
      className={({ isActive }) =>
        clsx(
          'hover:bg-menulink-hover-background text-grey-700 group flex items-center py-4 pr-2 font-medium transition hover:text-white focus:outline-none',
          desktop ? 'text-sm leading-5' : 'text-base leading-6',
          isActive && 'bg-menulink-active-background',
          className,
        )
      }
      onClick={(event) => {
        trackEvent('Nav Link Clicked', {
          brandName,
          targetPath: `${to}`,
          currentPath: window.location.pathname,
        });

        close?.();
        onClick?.(event);
      }}
    >
      <span>{icon}</span>
      {children}
      {badge && <div className="flex flex-1 justify-end">{badge}</div>}
    </NavLink>
  );
};
