const percentageFormatter = new Intl.NumberFormat('en', {
  style: 'percent',
  maximumFractionDigits: 2,
});

export type ProgressBarProps = {
  min?: number;
  max?: number;
  currentStep?: number;
};

export const ProgressBar = ({
  currentStep = 0,
  min = 0,
  max = 5,
}: ProgressBarProps) => {
  return (
    <div className="h-7.5 mb-6 w-full">
      <div className="mt-1 w-full rounded-sm bg-white">
        <div
          className="bg-positive-dark h-2 rounded-sm"
          role="progressbar"
          aria-valuenow={currentStep}
          aria-valuemin={min}
          aria-valuemax={max}
          style={{ width: percentageFormatter.format(currentStep / max) }}
        ></div>
      </div>
    </div>
  );
};
