export const calculateAge = (dateOfBirth: Date | string): number => {
  const today = new Date(); // Current date
  const birthDate = new Date(dateOfBirth); // Input date

  let age = today.getFullYear() - birthDate.getFullYear(); // Calculate the difference in years

  const monthDifference = today.getMonth() - birthDate.getMonth(); // Calculate month difference
  const dayDifference = today.getDate() - birthDate.getDate(); // Calculate day difference

  // Adjust the age if the current date is before the birth date in the current year
  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--;
  }

  return age;
};
