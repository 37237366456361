import { SocialProofStar } from './SocialProofStar';

type SocialProofCardProps = {
  author: string;
  avatar: string;
  rating: number;
  quote: string;
};

export const SocialProofCard = ({
  author,
  avatar,
  rating,
  quote: quote,
}: SocialProofCardProps) => {
  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="mr-4 h-14 w-14">
          <img src={avatar} alt={author} />
        </div>
        <div className="text-grey-900 font-medium">
          <div>{author}</div>
          <div className="flex items-center">
            {Array.from({ length: 5 }).map((_, index) => {
              const filled = index < rating;
              return <SocialProofStar key={index} filled={filled} />;
            })}
          </div>
        </div>
      </div>
      <p className="text-grey-500">&quot;{quote}&quot;</p>
    </div>
  );
};
