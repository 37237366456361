export const AffirmLogo = () => {
  return (
    <svg
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Affirm logo</title>
      <g clipPath="url(#clip0_3276_12264)">
        <path
          d="M5.27079 15.3999C4.4761 14.1868 4.0242 12.7814 3.96294 11.3324C3.90168 9.88351 4.23332 8.44497 4.92277 7.16911C5.61222 5.89325 6.63381 4.82755 7.87941 4.08482C9.125 3.34209 10.5482 2.94996 11.9985 2.94996C13.4487 2.94996 14.8719 3.34209 16.1175 4.08482C17.3631 4.82755 18.3847 5.89325 19.0742 7.16911C19.7636 8.44497 20.0953 9.88351 20.034 11.3324C19.9727 12.7814 19.5209 14.1868 18.7262 15.3999H20.9779C21.7258 13.8753 22.0731 12.1854 21.9872 10.4894C21.9012 8.79342 21.3848 7.14727 20.4865 5.70613C19.5883 4.26498 18.3379 3.07632 16.8531 2.25218C15.3683 1.42805 13.6982 0.995605 12 0.995605C10.3018 0.995605 8.63166 1.42805 7.1469 2.25218C5.66213 3.07632 4.41169 4.26498 3.51346 5.70613C2.61522 7.14727 2.09878 8.79342 2.01282 10.4894C1.92686 12.1854 2.27421 13.8753 3.02212 15.3999H5.27079Z"
          fill="#4A4AF4"
        />
        <path
          d="M12.1342 6.24805C10.6697 6.24805 8.98398 6.93912 8.06865 7.66834L8.90313 9.4273C9.63692 8.75606 10.8238 8.18245 11.8932 8.18245C12.9108 8.18245 13.4706 8.52112 13.4706 9.20762C13.4706 9.66528 13.0984 9.92768 12.3951 9.99175C9.75744 10.2343 7.70709 11.0596 7.70709 13.084C7.70709 14.6905 8.86498 15.6607 10.7704 15.6607C11.3432 15.6615 11.9059 15.5097 12.4005 15.2209C12.8952 14.9321 13.3041 14.5168 13.585 14.0177V15.3983H15.9619V9.61037C15.9619 7.21983 14.299 6.25415 12.1342 6.25415V6.24805ZM11.4401 13.8041C10.6773 13.8041 10.2487 13.4746 10.2487 12.9345C10.2487 11.8056 11.6217 11.5341 13.4218 11.5341C13.4218 12.7149 12.6316 13.8041 11.4386 13.8041H11.4401Z"
          fill="#060809"
        />
      </g>
      <defs>
        <clipPath id="clip0_3276_12264">
          <rect
            width="20"
            height="14.6606"
            fill="white"
            transform="translate(2 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
