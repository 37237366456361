import { dollars } from '../utilities/currency';

export const CurrencyDisplay = ({
  priceInCents,
  language = 'en',
  currency = 'CAD',
}: {
  priceInCents: number;
  language?: string;
  currency?: string;
}) => {
  const priceInDollars = dollars.fromCents(priceInCents);
  const formatter = new Intl.NumberFormat(language, {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 2,
  });

  return <span>{formatter.format(priceInDollars)}</span>;
};
