// Remove me when all forms using a phone number input with Formik are converetd to react hook form
import { useField, useFormikContext } from 'formik';
import {
  PhoneNumberInputImpl,
  yupPhoneNumberValidationSchema,
} from '../PhoneNumberInput/PhoneNumberInputImpl';
import { useRef } from 'react';

type PhoneNumberInputProps = Omit<
  React.HTMLProps<HTMLInputElement>,
  'value' | 'defaultValue'
> & {
  name: string;
  label?: string;
  dataCy?: string;
};

export const PhoneNumberInput = ({ name, ...props }: PhoneNumberInputProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField({ name, ref });

  return (
    <PhoneNumberInputImpl
      {...props}
      {...field}
      onChange={(value) => setFieldValue(name, value)}
      error={meta.touched && meta.error ? meta.error : undefined}
      ref={ref}
    />
  );
};

PhoneNumberInput.yupValidationSchema = yupPhoneNumberValidationSchema;
