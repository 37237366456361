import { StripeVerificationSessionStatusEnum as StatusEnum } from '@pm/graphql';
import { useTranslation } from 'react-i18next';
import { profileRoutes } from '@pm/core/src/modules/patientProfile/profile-routes';
import { format, parse } from 'date-fns';
import { FunctionComponent } from 'react';
import { CardTitle } from '../CardTitle';
import { ButtonLink } from '../ButtonLink';
import { Card } from '../Card';

type Props = {
  status?: StatusEnum | null;
  date?: string | null;
};

export const IdentificationCard: FunctionComponent<Props> = ({
  status,
  date,
}) => {
  const { t } = useTranslation('identification');
  return (
    <Card className="mt-4">
      <div className="flex justify-between">
        <CardTitle>{t('Identification')}</CardTitle>
      </div>
      {status === StatusEnum.Processing ? (
        <div className="text-grey-700 text-base font-normal">
          {t('InProgress')}
        </div>
      ) : status === StatusEnum.Verified ? (
        <>
          <div className="text-grey-500 text-sm">{t('Verified')}</div>
          <div className="text-grey-700 text-base font-normal">
            {date &&
              format(parse(date, 'yyyy-MM-dd', new Date()), 'MMMM dd, yyyy')}
            <img
              className="mb-1 ml-2 inline-block"
              src="/images/purple-circle-check-mark.svg"
              alt="Verified check mark"
            />
          </div>
        </>
      ) : (
        <div className="space-y-4">
          <div className="text-grey-700 text-base font-normal">
            {t('UploadPhotoId')}
          </div>
          <ButtonLink to={profileRoutes.updateIdentification.path}>
            {t('UploadId')}
          </ButtonLink>
          <ButtonLink
            to={profileRoutes.updateIdentification.path}
            variant="tertiary"
          >
            {t('WhyId')}
          </ButtonLink>
        </div>
      )}
    </Card>
  );
};
