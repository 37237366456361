import {
  ErrorMessage,
  FieldMetaProps,
  useField,
  useFormikContext,
} from 'formik';
import { ChangeEvent, FunctionComponent, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledInput, getDay, getMonth, getYear, makeDateString } from '../..';

interface InputProps {
  name: string;
  value: string;
  placeholder: string;
  meta: FieldMetaProps<unknown>;
  label?: ReactNode;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  subtext?: string;
}

const Input: FunctionComponent<InputProps> = ({
  name,
  value,
  placeholder,
  meta,
  label,
  onChange,
  subtext,
}) => {
  return (
    <div className="flex-grow">
      {label && (
        <label
          className="text-grey-500 float-left block text-xs"
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <StyledInput
        id={name}
        name={name}
        value={value}
        placeholder={placeholder}
        maxLength={placeholder.length}
        type="text"
        inputMode="numeric"
        onChange={onChange}
        aria-invalid={meta.touched && meta.error ? true : undefined}
        data-testid={name}
      />
      {subtext && (
        <div className="text-grey-500 text-xs font-medium">{subtext}</div>
      )}
    </div>
  );
};

interface Props {
  name: string;
  initialDate?: string | null;
  monthHint?: string;
  dayHint?: string;
  yearHint?: string;
  showSubtextHint?: boolean;
}

export const Datepicker: FunctionComponent<Props> = ({
  name,
  initialDate,
  monthHint,
  dayHint,
  yearHint,
  showSubtextHint = false,
}) => {
  const { t } = useTranslation('global');
  const [, meta] = useField(name);
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [date, setDate] = useState({
    month: initialDate?.length ? getMonth(initialDate) : '',
    day: initialDate?.length ? getDay(initialDate) : '',
    year: initialDate?.length ? getYear(initialDate) : '',
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedDate = { ...date, [event.target.name]: event.target.value };
    setDate(updatedDate);

    if (
      updatedDate.day &&
      updatedDate.month &&
      updatedDate.year &&
      Number(updatedDate.year) &&
      updatedDate.year.length === 4
    ) {
      const newDate = new Date(
        `${makeDateString(
          updatedDate.year,
          updatedDate.month,
          updatedDate.day,
        )}`.replace(/-/g, '/'),
      );
      const newDateDay = newDate.getDate();
      const isInvalid = newDateDay !== Number(updatedDate.day);

      const saveDate = isInvalid ? new Date('') : newDate;

      setFieldTouched(name, true, true);
      setFieldValue(name, saveDate, true);
    } else {
      setFieldValue(name, updatedDate, true);
    }
  };

  return (
    <div className="grid grid-cols-3 grid-rows-1 place-content-center gap-4">
      <Input
        name="month"
        value={date.month}
        meta={meta}
        label={t('Month')}
        placeholder="MM"
        onChange={handleChange}
        subtext={showSubtextHint ? monthHint : ''}
      />
      <Input
        name="day"
        value={date.day}
        meta={meta}
        label={t('Day')}
        placeholder="DD"
        onChange={handleChange}
        subtext={showSubtextHint ? dayHint : ''}
      />
      <Input
        name="year"
        value={date.year}
        meta={meta}
        label={t('Year')}
        placeholder="YYYY"
        onChange={handleChange}
        subtext={showSubtextHint ? yearHint : ''}
      />
      <ErrorMessage
        name={name}
        component="p"
        className="col-span-3 text-red-500"
      />
    </div>
  );
};
