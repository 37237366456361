import { StarIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';

type SocialProofStarProps = {
  filled: boolean;
};

export const SocialProofStar = ({ filled }: SocialProofStarProps) => {
  const colour = filled ? 'text-yellow-400' : 'text-grey-300';
  return <StarIcon className={clsx(colour, 'h-5 w-5')} />;
};
