import { AffirmLogo } from './AffirmLogo';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import { TermsOfService } from '../TermsOfService/TermsOfService';

interface AffirmPaymentSummaryProps {
  onChange?: () => void;
}

export const AffirmPaymentSummary = ({
  onChange,
}: AffirmPaymentSummaryProps) => {
  const { t } = useTranslation('payment-methods', {
    keyPrefix: 'PaymentMethodSummary.AffirmSummary',
  });
  return (
    <>
      <div className="flex">
        <span className="my-0.5 w-6">
          <AffirmLogo />
        </span>
        <div className="text-grey-900 ml-3">{t('Title')}</div>
        {onChange ? (
          <button
            type="button"
            onClick={onChange}
            className="text-hyperlink-foreground ml-auto cursor-pointer hover:opacity-80"
          >
            {t('ChangePaymentMethodButton')}
          </button>
        ) : (
          <CheckCircleIcon className="text-brand-primary-500 float-right my-0.5 ml-auto h-5" />
        )}
      </div>
      <TermsOfService
        link="https://talkwithfrida.com/terms-of-use"
        service="Affirm"
      />
    </>
  );
};
