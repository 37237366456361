import { SocialProofCard } from './SocialProofCard';
import { Carousel } from '../Carousel/Carousel';
import { CarouselItem } from '../Carousel/CarouselItem';
import { CarouselItems } from '../Carousel/CarouselItems';

const reviews = [
  {
    id: 1,
    author: 'Gaby',
    avatar: '/images/social-proof/avatar-gaby.png',
    rating: 5.0,
    quote:
      'Wonderful experience. Way better than waiting months to see a psychologist. I would recommend Frida to anyone!',
  },
  {
    id: 2,
    author: 'Julianne W.',
    avatar: '/images/social-proof/avatar-julianne.png',
    rating: 5.0,
    quote:
      'I highly recommend Frida for anyone who is seeking an initial ADHD diagnosis. Truly an amazing service.',
  },
  {
    id: 3,
    author: 'Fabiana V.',
    avatar: '/images/social-proof/avatar-fabiana.png',
    rating: 5.0,
    quote:
      'I was overwhelmed searching for a referral that would take months, then I found Frida, easy to book appointments, excellent professionals and 10/10 patient care.',
  },
];

export const SocialProof = () => {
  return (
    <div className="max-w-512 flex">
      <Carousel>
        <CarouselItems>
          {reviews.map((review) => (
            <CarouselItem key={review.id}>
              <SocialProofCard {...review} />
            </CarouselItem>
          ))}
        </CarouselItems>
      </Carousel>
    </div>
  );
};
