import { FunctionComponent, useState } from 'react';
import { ErrorMessage, useField } from 'formik';
import { QuestionnaireLabel } from '../QuestionnaireLabel';
import { ToggleButton } from '../ToggleButton';

type Props = {
  name: string;
  label?: string;
  options: string[] | { name: string; value: string }[];
  preSelected?: string;
};

type OptionState = {
  name: string;
  value: string;
  selected: boolean;
};

export const MultiSelectField: FunctionComponent<Props> = ({
  name,
  label,
  options,
  preSelected,
}) => {
  const [field, , helpers] = useField(name);
  const { setValue } = helpers;
  const { value } = field;
  const [optionStates, setOptionStates] = useState<OptionState[]>(
    options.map((option) => {
      return {
        name: typeof option === 'string' ? option : option.name,
        value: typeof option === 'string' ? option : option.value,
        selected:
          value != null &&
          (value.includes(option) ||
            (typeof option === 'string' && preSelected?.includes(option)) ||
            (typeof option !== 'string' && value.includes(option.name)))
            ? true
            : false,
      };
    }),
  );

  return (
    <div className="mb-3 w-full text-center">
      <div role="radiogroup">
        <QuestionnaireLabel label={label} />
        <div className="mx-auto grid grid-cols-1 gap-x-4 gap-y-4">
          {optionStates.map((option, index) => (
            <ToggleButton
              key={option.value + index}
              text={option.name}
              selected={option.selected}
              vertical
              onClick={() => {
                const newOptionStates: OptionState[] = JSON.parse(
                  JSON.stringify(optionStates),
                );
                const currentOption = newOptionStates.find(
                  (optionState) => optionState.value === option.value,
                );
                if (!currentOption) {
                  return;
                }
                currentOption.selected = !option.selected;
                if (
                  option.value === 'None of the above' ||
                  option.value === 'None' ||
                  option.value === 'No to all'
                ) {
                  newOptionStates
                    .filter(
                      (optionState) =>
                        optionState.value !== 'None of the above' &&
                        optionState.value !== 'None' &&
                        optionState.value !== 'No to all',
                    )
                    .forEach((optionState) => (optionState.selected = false));
                } else {
                  newOptionStates
                    .filter(
                      (optionState) =>
                        optionState.value === 'None of the above' ||
                        optionState.value === 'None' ||
                        optionState.value === 'No to all',
                    )
                    .forEach((optionState) => (optionState.selected = false));
                }
                setOptionStates(newOptionStates);
                setValue(
                  newOptionStates
                    .filter((optionState) => optionState.selected)
                    .map((optionState) => optionState.value)
                    .join(','),
                );
              }}
            />
          ))}
        </div>
      </div>
      <ErrorMessage name={name} component="p" className="mt-2 text-red-400" />
    </div>
  );
};
