import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { PaymentMethodPartsFragment } from '@pm/graphql';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrencyDisplay } from '../CurrencyDisplay';
import { StripeCreditCardIcon } from './StripeCreditCardIcon';
import { TermsOfService } from '../TermsOfService/TermsOfService';
import { formatDateToShortFormat } from '../../utilities/dateUtilities';

type SummaryRowProps = {
  label: ReactNode;
  children: ReactNode;
};

const SummaryRow = ({ label, children }: SummaryRowProps) => {
  return (
    <div className="flex justify-between py-4">
      {label}
      {children}
    </div>
  );
};
interface StripePaymentSummaryProps {
  paymentMethod: PaymentMethodPartsFragment;
  priceInCents: number;
  onChange?: () => void;
}

export const StripePaymentSummary = ({
  paymentMethod,
  priceInCents,
  onChange,
}: StripePaymentSummaryProps) => {
  const { t } = useTranslation('payment-methods', {
    keyPrefix: 'PaymentMethodSummary.StripeSummary',
  });
  const billingDate = formatDateToShortFormat(new Date());

  return (
    <>
      <div className="divide-grey-300 text-grey-900 divide-y">
        <SummaryRow
          label={<StripePaymentMethodLabel paymentMethod={paymentMethod} />}
        >
          {onChange ? (
            <button
              type="button"
              onClick={onChange}
              className="text-hyperlink-foreground float-right cursor-pointer hover:opacity-80"
            >
              {t('ChangePaymentMethodButton')}
            </button>
          ) : (
            <CheckCircleIcon className="text-brand-primary-500 float-right my-0.5 ml-auto h-5" />
          )}
        </SummaryRow>
        <SummaryRow label={t('BillingTotal')}>
          <CurrencyDisplay priceInCents={priceInCents} />
        </SummaryRow>
        <SummaryRow label={t('BillingDate')}>
          <div>{billingDate}</div>
        </SummaryRow>
      </div>
      <TermsOfService
        link="https://talkwithfrida.com/terms-of-use"
        service="Stripe"
      />
    </>
  );
};

export const StripePaymentMethodLabel = ({
  paymentMethod,
}: {
  paymentMethod: PaymentMethodPartsFragment;
}) => {
  const { t } = useTranslation('payment-methods');
  const expirationDate = t(
    'PaymentMethodSummary.StripeSummary.LastFourAndExpirationDate',
    {
      last4: paymentMethod.last4,
      month: paymentMethod.expMonth,
      year: paymentMethod.expYear,
    },
  );
  return (
    <div className="flex flex-1 items-center gap-4">
      <StripeCreditCardIcon brandType={paymentMethod.brand} />
      <span>{expirationDate}</span>
    </div>
  );
};
